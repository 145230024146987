<template>
  <section class="contact-us" :style="{ backgroundColor: backgroundColor}">

    <h1 class="contact-info_title">Contact</h1>
    <div class="contact-box"
         :style="'boxShadow: -32px 39px 0 0 ' + this.contactShadowColor + ', 26px 15px 0 0 #AEAFAF'">

      <v-container fluid>
        <div class="contact-box-form" v-if="!isFormDataSent">
          <v-form ref="form"
                  class="mx-2"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent="submit"
                  content-class="elevation-0">

            <div class="form-content">

              <label>Name</label>
              <v-text-field
                  v-model="form.fullName"
                  :rules="rules.nameRules"
                  required
                  single-line
                  :color="inputBorderColor">
              </v-text-field>

              <label>Email</label>
              <v-text-field
                  v-model="form.email"
                  :rules="rules.emailRules"
                  single-line
                  required
                  :color="inputBorderColor">
              </v-text-field>

              <label>Subject</label>
              <v-text-field
                  v-model="form.subject"
                  :rules="rules.descriptionRules"
                  required
                  :color="inputBorderColor">
              </v-text-field>

              <label>Message</label>
              <v-textarea
                  v-model="form.messageText"
                  :rules="rules.counter"
                  rows="1"
                  required
                  :color="inputBorderColor">
              </v-textarea>

              <!--              <div></div>-->
              <!--              <div class="attach-file">-->
              <!--                <v-file-input-->
              <!--                    single-line-->
              <!--                    :label="label"-->
              <!--                    @change="onFileChange"-->
              <!--                    show-size-->
              <!--                    multiple-->
              <!--                    truncate-length="33"-->
              <!--                    :color="inputBorderColor">-->
              <!--                </v-file-input>-->
              <!--              </div>-->

              <div></div>
              <div class="inquire-check">

                <input type="radio" name="checked[]" id="development-check" value="development" v-model="checkedNames">
                <label for="development-check" class="checkbox-label--development">
                  <span class="outer-diamond outer-diamond--development">
                    <span class="inner-diamond inner-diamond--development"></span>
                  </span>
                  <span>Development</span>
                </label>

                <input type="radio" name="checked[]" id="design-check" value="design" v-model="checkedNames">
                <label for="design-check" class="checkbox-label--design">
                  <span class="outer-diamond outer-diamond--design">
                    <span class="inner-diamond inner-diamond--design"></span>
                  </span>
                  <span>Design</span>
                </label>

                <input type="radio" name="checked[]" id="support-check" value="support" v-model="checkedNames">
                <label for="support-check" class="checkbox-label--support">
                  <span class="outer-diamond outer-diamond--support">
                    <span class="inner-diamond inner-diamond--support"></span>
                  </span>
                  <span>Support</span>
                </label>

                <input type="radio" name="checked[]" id="hr-check" value="hr" v-model="checkedNames">
                <label for="hr-check" class="checkbox-label--hr">
                  <span class="outer-diamond outer-diamond--hr">
                    <span class="inner-diamond inner-diamond--hr"></span>
                  </span>
                  <span>HR</span>
                </label>

              </div>

              <div></div>
              <div class="button-contact-box">
                <button type="submit" id="submit" class="button-section-contact">
                  Submit
                  <i class="fas fa-chevron-right" aria-hidden="true"></i>
                </button>
                <div id="loader"></div>
              </div>

            </div>

            <!--            <v-card flat>
                          <v-snackbar
                              v-model="snackbar"
                              absolute
                              top
                              center
                              color="success">

                            <span>Thank you! <br> Your message was submitted.
                            Someone from our team will reach out to you</span>
                            <v-icon dark>
                              mdi-checkbox-marked-circle
                            </v-icon>
                          </v-snackbar>
                        </v-card>-->

          </v-form>
        </div>

        <div class="notification-message" v-if="isFormDataSent">
          <div class="thank-you">Thank you,
            <div class="name"> {{ form.fullName }} !</div>
          </div>
          <p>We will get back to you as soon as possible, </p>
          <p class="team">Scriptics Team</p>

        </div>
      </v-container>

    </div>

  </section>
</template>

<script>
import axios from "axios";
import {CMS} from '@/main';

export default {
  props: {
    backgroundColor: {
      type: String,
    },
    displayContact: {
      type: String,
    },
    colorBoxUs: {
      type: String,
    },
    value: {
      type: [Array, String]
    },
    accept: {
      type: String,
      default: "*"
    },
    label: {
      type: String,
      default: "Attach a file"
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
  },
  data() {
    const defaultForm = Object.freeze({
      fullName: null,
      email: null,
      subject: null,
      messageText: "",
      filename: "",
      checked: 'development',
    });
    const isFormDataSent = false;
    return {
      isFormDataSent,
      contact: 'contact@scriptics.ro',
      hr: 'hr@scriptics.ro',
      development: 'development@scriptics.ro',
      design: 'design@scriptics.ro',
      support: 'support@scriptics.ro',
      contactShadowColor: '#2b98c7',
      inputBorderColor: '#2b98c7',
      contactUscolor: this.backgroundColor,
      displayNone: this.displayContact,
      colorUs: this.colorBoxUs,

      form: Object.assign({}, defaultForm),
      valid: true,
      filename: '',

      rules: {
        nameRules: [
          val => !!val || 'Name is required',
          val => (val || '').length > 0 || 'This field is required',
        ],
        emailRules: [
          val => !!val || 'E-mail is required',
          val => /.+@.+\..+/.test(val) || 'E-mail must be valid',
          val => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(val) || 'Invalid e-mail.'
          },
        ],
        descriptionRules: [
          v => v && v.length > 0 || 'You must input a subject',
        ],
        counter: [
          val => val.length <= 20 || 'Max 350 characters',
        ],
      },
      snackbar: false,
      defaultForm,

    }
  },

  watch: {
    value(v) {
      this.filename = v;
    }
  },

  mounted() {
    this.filename = this.value;
  },

  computed: {
    formIsValid() {
      return (
          this.form.fullName &&
          this.form.email &&
          this.form.subject &&
          this.form.messageText &&
          this.form.checked
      )
    },

    checkedNames: {
      get: function () {
        return this.form.checked
      },

      set: function (newVal) {
        // console.log(newVal);  //with computed we have true/false value instead of array
        this.checked = newVal;
        switch (newVal) {
          case 'hr':
            this.contactShadowColor = '#3a3a3a';
            this.inputBorderColor = '#3a3a3a';
            break;

          case 'development':
            this.contactShadowColor = '#2b98c7';
            this.inputBorderColor = '#2b98c7';
            break;

          case 'design':
            this.contactShadowColor = '#c22b47';
            this.inputBorderColor = '#c22b47';
            break;

          case 'support':
            this.contactShadowColor = '#407841';
            this.inputBorderColor = '#407841';
            break;

          default:
            this.contactShadowColor = 'transparent';
        }
      },
    },
  },

  methods: {
    submit() {
      if (this.form.fullName && this.form.email && this.form.subject && this.form.messageText) {
        document.getElementById('submit').style.opacity = '0';
        document.getElementById('loader').style.opacity = '1';

        axios.post(CMS + 'api/contact-us-mail', {
          fullName: this.form.fullName,
          email: this.form.email,
          subject: this.form.subject,
          messageText: this.form.messageText,
          //filename: this.form.filename,
          checked: 'development',
        }, {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          }
        })
            .then((response) => {
              console.log(response);
              this.isFormDataSent = true;
            })
            .catch(function (error) {
              console.log(error);
              document.getElementById('submit').style.opacity = '1';
              document.getElementById('loader').style.opacity = '0';
            });
      }


    },
    getFormData(files) {
      const data = new FormData();
      [...files].forEach(file => {
        data.append('data', file, file.name); // currently only one file at a time
      });
      return data;
    },
    onFocus() {
      if (!this.disabled) {
        // debugger;
        //  this.$refs.fileInput.click();
      }
    },
    onFileChange($event) {
      const files = $event.target.files || $event.dataTransfer.files;
      const form = this.getFormData(files);
      if (files) {
        if (files.length > 0) {
          this.filename = [...files].map(file => file.name).join(', ');
        } else {
          this.filename = null;
        }
      } else {
        this.filename = $event.target.value.split('\\').pop();
      }
      this.$emit('input', this.filename);
      this.$emit('formData', form);
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.form = Object.assign({}, this.defaultForm)
      this.$refs.form.reset()
    },
  },

}
</script>

<style scoped lang="scss">
$hr-color: #3a3a3a;
$development-color: #2b98c7;
$design-color: #c22b47;
$support-color: #407841;

.v-text-field .v-input__control .v-input__slot {
  min-height: 100px !important;
  display: flex !important;
  align-items: center !important;
}

.contact-us {
  display: block;
  text-align: center;
  background-image: linear-gradient(337deg, #1a6bb1 10%, #fafafa 42%, #91293e 88%);

  h1 {
    color: #2d98c8;
    font-size: 100px;
    font-weight: 400;
  }

  .notification-message {
    color: #3a3a3a;
    text-align: right;
    vertical-align: text-top;
    //text-shadow: 1.5px 1.5px 3px #000000;
    font-family: "Heebo", sans-serif;
    font-size: 35pt;
    font-weight: 300;
    margin-top: 10%;

    .thank-you {
      float: right;
      vertical-align: text-top;

      .name {
        float: right;
        font-weight: 400;
      }
    }

    p {
      margin-top: 5%;
      float: right;
    }
  }
}


.contact-box {
  padding: 5%;
  margin: 30px auto;
  width: 60%;
  height: 90vh;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(73deg, #ffffff, #c95e95, #3281b8) 1;
  background-color: #FFFFFF;

  &-form {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    text-align: center;

    .form-content {
      display: grid;
      grid-template-columns: max-content minmax(0, 1fr);
      grid-gap: 0;

      label {
        margin: 5% 15% 5% 0;
        text-align: right;
        font-size: 35pt;
        font-weight: 200;
      }

      .inquire-check {
        label {
          margin: 0;
        }
      }

      .v-text-field {
        margin: 5%;
        display: flex;
        align-items: flex-end;
        padding-top: 0;
        font-size: small;
        z-index: 10;
      }

      .attach-file {
        .v-text-field {
          align-items: flex-start;
        }
      }

      .inquire-check {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;
        text-align: left;

        input {
          display: none;
        }

        label {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 19px;
          height: 20px;
          cursor: pointer;
          font-weight: 400;
          letter-spacing: 0.205px;

          &:first-of-type {
            justify-content: flex-start;
          }

          &:hover {
            cursor: pointer;
            opacity: .8;
            transition-delay: .2ms;
            letter-spacing: 0;
            font-weight: bold;
          }

          &.checkbox-label--hr {
            color: $hr-color;
          }

          &.checkbox-label--development {
            color: $development-color;
          }

          &.checkbox-label--design {
            color: $design-color;
          }

          &.checkbox-label--support {
            color: $support-color;
          }

          .outer-diamond {
            position: relative;
            margin-right: 10px;
            margin-left: 10px;
            height: 20px;
            width: 20px;
            transform: rotate(45deg);
            background-color: #ffffff;
            border: 1px solid;

            &--hr {
              border-color: $hr-color;
            }

            &--development {
              border-color: $development-color;
            }

            &--design {
              border-color: $design-color;
            }

            &--support {
              border-color: $support-color;
            }

            .inner-diamond {
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 12px;
              width: 12px;
              opacity: 0;

              &--hr {
                background-color: $hr-color;
              }

              &--development {
                background-color: $development-color;
              }

              &--design {
                background-color: $design-color;
              }

              &--support {
                background-color: $support-color;
              }
            }
          }
        }
      }

      .button-contact-box {
        text-align: left;
        padding: 5% 0;

        #loader {
          opacity: 0;
          margin: -10% 15% 0 15%;
          border: 4px solid #cfcfcf;
          border-radius: 50%;
          border-top: 4px solid #1c1c1c;;
          width: 5vh;
          height: 5vh;
          -webkit-animation: spin 2s linear infinite; /* Safari */
          animation: spin 2s linear infinite;
        }

        /* Safari */
        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .button-section-contact {
          font-size: 23pt;
          font-weight: 200;
          color: #1c1c1c;
          position: relative;
          transition: color 0.5s, transform 0.2s, background-color 0.2s;
          outline: none;
          margin: 0 10px;
          padding: 5px 20px;
          border: 2px solid;

          &:active {
            transform: translateY(3px);
          }

          &::after, &::before {
            border-radius: 3px;
          }

          .shrink-border {
            background-color: transparent;
            color: #1c1c1c;
          }

          &:hover {
            background-color: transparent;
            box-shadow: none;
            color: $development-color;

            i {
              color: $development-color;
            }
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #1c1c1c;
            transition: opacity 0.3s, border 0.3s;
          }

          &:hover::before {
            opacity: 0;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: 3px solid $development-color;
            opacity: 0;
            z-index: -1;
            transform: scaleX(1.1) scaleY(1.3);
            transition: transform 0.3s, opacity 0.3s;
          }

          &:hover::after {
            opacity: 1;
            transform: scaleX(1) scaleY(1);
          }

        }
      }
    }
  }
}

.contact-info_title {
  font-size: 60px;
  padding-top: 20px;
  text-shadow: 2.5px 2.5px 4px #000000;
}

.theme--light {

  &.v-input {
    margin-bottom: 8px;
    text-decoration: none;
    border-color: transparent;
    font-size: 22px;
    font-weight: 400;
  }
}

.form-section .contact-form .form-groups.inquire-check {
  position: relative;
}

#contact form span {
  color: #2d98c8;
}

#design-check:checked + label span span, #development-check:checked + label span span, #support-check:checked + label span span, #hr-check:checked + label span span {
  opacity: 1;
}

i {
  font-size: 17pt;
  color: black;
  z-index: 99;
  -webkit-background-clip: text;
}

#contact form input[type=submit] {
  display: inline-block;
  width: 23%;
  padding: 20px 20px;
  cursor: pointer;
  vertical-align: middle;
  line-height: 46px;
  font-weight: 900;
  font-size: 30px;
}

@media only screen and (max-width: 1990px) {
  .contact-info_line-separator {
    width: 32em
  }
}

@media only screen and (max-width: 1880px) {
  .contact-info_line-separator {
    width: 29em;
  }
}

@media only screen and (max-width: 1660px) {
  .contact-info_line-separator {
    width: 27em;
  }

  .contact-info {
    width: 71%;
  }

  .contact-info_title {
    font-size: 80px;
  }
}

@media only screen and (max-width: 1440px) {
  .contact-box {
    padding: 5% 3%;
    height: 90vh;
  }
  .contact-info_line-separator {
    width: 23em;
  }
}

@media only screen and (max-width: 1300px) {


  .contact-box {
    width: 95%;
    margin: 0 auto;
    height: 90vh;
  }

  .contact-info {
    margin-bottom: 30px;
  }

  .contact-box {
    padding: 5% 15%;
    margin-right: 29px;
    height: 90vh;
  }

  .contact-info a {
    text-align: left;
  }
}

@media only screen and (max-width: 1024px) {
  .contact-us {
    flex-direction: column;

    .contact-info {
      width: 100%;
      text-align: center;
      padding: 0;

      a {
        text-align: center;
        padding-left: 0;
      }

      .contact-info_line-separator {
        width: 50%;
        right: 50%;
        transform: translateX(50%);
      }
    }

    .contact-box {
      width: 90%;
      height: 90vh;
    }
  }
}

@media only screen and (max-width: 950px) {

  .contact-us {
    .notification-message {
      //text-shadow: 1px 1px 2.5px #000000;
      font-size: 19pt;
      margin-top: 20%;

      .thank-you {
        margin-right: 5%;
      }

      p {
        width: 100%;
        margin: 10% 5% 0 0;
      }
    }
  }
  .contact-box {
    box-shadow: none;
    border-width: .25em;
    margin-right: 21px;
    height: 90vh;
  }

  .inquire-check label {
    font-size: 21px;
  }

  .contact-box {
    padding: 5% 0 5% 1%;
    height: 90vh;
  }
}

@media only screen and (max-width: 768px) {
  .contact-us {

    .notification-message {
      //text-shadow: 1px 1px 2.5px #000000;
      font-size: 19pt;
      margin-top: 20%;

      p {
        margin-top: 10%;
      }
    }

    .contact-box {
      padding: 10% 5%;
      margin: 0 auto;
      height: 90vh;

      &-form {
        .form-content {
          label {
            font-size: 20px;
          }

          .button-contact-box {
            .button-section-contact {
              font-size: 25pt;
            }
          }

          .inquire-check {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 20px;

            label {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }

  .button-section-contact {
    font-size: 36pt;
  }

  i {
    font-size: 20pt;
  }
  .contact-info a {
    font-size: 27px;
  }
}

@media only screen and (max-width: 500px) {
  .contact-us {

    .notification-message {
      //text-shadow: 1px 1px 2.5px #000000;
      font-size: 20pt;
      margin-top: 40%;

      p {
        margin-top: 20%;
      }
    }

    .contact-box {
      padding: 0;
      height: 100vh;

      &-form {
        .form-content {

          button {
            font-size: medium;

            i {
              font-size: medium;
            }
          }

          label {
            margin: 0;
            padding: 0;
            font-size: medium;
          }

          grid-template-columns: repeat(1, 1fr);
          gap: 0;


          .button-contact-box {
            margin: auto;

            #loader {
              margin: -30% auto 0 auto;
            }

            .button-section-contact {
              font-size: 20pt;
            }
          }
        }
      }
    }

    h1 {
      font-size: 5em;
    }

  }

  .contact-info_title {
    font-size: 55px;
  }

  .contact-info {
    width: 55%;
  }

  .contact-info a {
    font-size: 23px;
  }

  .contact-info_line-separator {
    width: 31em;
  }

  .contact-box {
    margin-right: 10px;
    height: 90vh;

    &-form {
      .form-content {
        .button-section-contact {
          .button-section_submit-contact, i {
            font-size: 26px;
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 375px) {
  .contact-us {
    .notification-message {
      //text-shadow: 1px 1px 2.5px #000000;
      font-size: 20pt;
      margin-top: 40%;

      p {
        margin-top: 20%;
      }
    }

    .contact-box {
      height: 90vh;

      &-form {
        .form-content {

          button {
            font-size: small;

            i {
              font-size: small;
            }
          }

          label {
            margin: 0;
            padding: 0;
            font-size: small;
          }

          .v-text-field {
            margin: 0;
            padding: 0;
          }

          .inquire-check {
            column-gap: 10px;
          }
        }
      }
    }
  }

  .contact-info {
    padding-left: 10px;
  }

}
</style>
